import React, { FC, useContext, memo } from 'react'
import { Box, H4 } from '@vp/swan'
import ProductDetails from '../ProductDetails'
import { PreviewContext } from '../../contexts/preview-images.context'
import './index.scss'
import { FormattedMessage } from 'react-intl'
import { useProductDetailsContext } from '../../contexts/app.context'
import { isProductDetailsShown } from '../../utils/utils'
import clsx from 'clsx'
import NoPhostosUploaded from '../NoPhotosUploaded'
import PreviewComponent from '../Preview'

const PreviewWrapper: FC<unknown> = () => {
  const { previewList } = useContext(PreviewContext)
  const productDetails = useProductDetailsContext()

  const showProductDetails = isProductDetailsShown(productDetails.productName, productDetails.productSize)
  return (
    <Box as="section" className="preview-wrapper-container" paddingX={4} pt={4}>
      <ProductDetails />
      <H4 fontSize="x1" pb={2} fontWeight="bold" textAlign={'left'} mt={showProductDetails ? 7 : 4}>
        {!previewList.length ? (
          <FormattedMessage defaultMessage="Select photos from your phone to send to Vistaprint" description="Header text for when no photos are uploaded" />
        ) : (
          <FormattedMessage defaultMessage="Recently Uploaded" description="Header text for when photos are uploaded" />
        )}
      </H4>

      <Box className={clsx(showProductDetails && 'preview-header-container', !showProductDetails && 'preview-header-container-full')}>
        {!previewList.length ? <NoPhostosUploaded /> : <PreviewComponent previewList={previewList} />}
      </Box>
    </Box>
  )
}

export default memo(PreviewWrapper)
