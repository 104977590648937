import { useCallback } from 'react'
import { useToast } from '../contexts/toast.context'

export function useErrorToastWithDuration() {
  const toast = useToast()

  return useCallback(
    (defaultMessage: string) => {
      toast({
        type: 'ADD',
        config: {
          skin: 'error',
          content: defaultMessage,
          duration: 15000,
        },
      })
    },
    [toast],
  )
}
