import { getRootClassNames, ScreenClassProvider, SwanHead, SwanProvider, SWAN_STYLE_KEY_MAP, useBrowserClasses } from '@vp/swan'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { SwanProps } from '../../types/swan.types'

const loadStyles = [SWAN_STYLE_KEY_MAP.spinner, SWAN_STYLE_KEY_MAP.alertBox, SWAN_STYLE_KEY_MAP.button]

const SwanConfiguration: React.FC<SwanProps> = ({ children, localeCulture }) => {
  const browser = useBrowserClasses()
  const rootClassName = getRootClassNames()

  return (
    <SwanProvider swanLocale={localeCulture} swanTenant={'vistaprint'}>
      <Helmet htmlAttributes={{ class: browser }} bodyAttributes={{ class: rootClassName }} />
      <SwanHead renderStyleContentAsChildren renderWith={Helmet} styleSheetKeys={loadStyles} />
      <ScreenClassProvider>{children}</ScreenClassProvider>
    </SwanProvider>
  )
}

export { SwanConfiguration }
