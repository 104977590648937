import { AlertBox, AlertBoxDismissButton, Typography } from '@vp/swan'
import { useMessages } from '../../hooks/intl.hooks'
import React, { useEffect, useRef, useState } from 'react'
import { ToastSkin } from '../../types/toast.type'

export type ToastProps = {
  remove: () => void
  message: string | JSX.Element
  duration?: number
  skin: ToastSkin
}

export const Toast: React.FC<ToastProps> = ({ remove, message, skin = 'standard', duration = 5000 }) => {
  const { msgDismissAlert } = useMessages()
  const [dismissed, setDismissed] = useState(false)
  const removeRef = useRef<() => void>()
  removeRef.current = remove
  useEffect(() => {
    const id = setTimeout(() => {
      if (removeRef.current) {
        removeRef.current()
      }
    }, duration)
    return () => clearTimeout(id)
  }, [duration])
  return (
    <AlertBox
      className="toast-wrapper"
      skin={skin}
      dismissed={dismissed}
      onRequestDismiss={() => {
        setDismissed(true)
        remove()
      }}
      mb={4}
    >
      <Typography textAlign="left">{message}</Typography>
      <AlertBoxDismissButton visuallyHiddenLabel={msgDismissAlert()} />
    </AlertBox>
  )
}
