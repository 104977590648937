import { Box, LinkList, LinkListHeading, ListItem } from '@vp/swan'
import { Link, PageProps } from 'gatsby'
import React, { FC } from 'react'
import { PageContextType } from '../types/applications.types'

const NotFoundPage: FC<PageProps<unknown, PageContextType>> = () => (
  <Box align="center" mt={10}>
    <LinkListHeading>Available pages</LinkListHeading>
    <LinkList skin="standard">
      <ListItem>
        <Link to="en-us/qlp/upload">EN-US - Upload</Link>
      </ListItem>
      <ListItem>
        <Link to="en-ca/qlp/upload">EN-CA - Upload</Link>
      </ListItem>
      <ListItem>
        <Link to="es-us/qlp/upload">ES-US - Upload</Link>
      </ListItem>
      <ListItem>
        <Link to="fr-ca/qlp/upload">FR-CA - Upload</Link>
      </ListItem>
    </LinkList>
  </Box>
)
export default NotFoundPage
