import { useIntl } from 'react-intl'

export function useMessages() {
  const intl = useIntl()
  const unAuthError = (total: number, unAUthorized: number) => {
    if (unAUthorized && total) {
      return intl.formatMessage(
        {
          defaultMessage:
            '{unAUthorized} of {total} images failed to upload. Link expired. Go back VistaPrint on your computer and select “Add from your phone” to get a fresh link.',
          description: 'Message for failed uploading for url failed reason',
        },
        { total, unAUthorized },
      )
    } else {
      return ''
    }
  }
  const somethingWentWrongError = (total: number, failedFiles: number) => {
    if (failedFiles && total) {
      return intl.formatMessage(
        {
          defaultMessage:
            'Something went wrong!! {failedFiles} of {total} images failed to upload. Go back VistaPrint on your computer and select “Add from your phone” to get a fresh link.',
          description: 'Message for failed uploading for unknown reason',
        },
        { total, failedFiles },
      )
    } else {
      return ''
    }
  }

  const maxUploadError = (maxUploads: number) => {
    if (maxUploads) {
      return intl.formatMessage(
        {
          defaultMessage: 'You can upload only upto {maxUploads} number of files.',
          description: 'Error message for uploads more than given number',
        },
        { maxUploads },
      )
    } else {
      return ''
    }
  }

  const invalidFormatError = (validFormats: string, fileName: string) => {
    if (validFormats && fileName) {
      return intl.formatMessage(
        {
          defaultMessage: '{fileName} is not using a supported extension {validFormats}.',
          description: 'Error message when invalid files are uploaded.',
        },
        { validFormats, fileName },
      )
    } else {
      return ''
    }
  }

  const maxFileSizeError = (maxFileSize: number, fileName: string, fileSize: number) => {
    if (maxFileSize && fileName && fileSize) {
      return intl.formatMessage(
        {
          defaultMessage: '{fileName} exceeds the max file size {maxFileSize} Kilo bytes. File size is {fileSize} Kilo bytes.',
          description: 'Error message when invalid files are uploaded.',
        },
        { maxFileSize, fileName, fileSize },
      )
    } else {
      return ''
    }
  }

  const callOutMessage = (remTime: string) => {
    if (typeof remTime === 'string' && remTime !== '') {
      return intl.formatMessage(
        {
          defaultMessage: 'Start upload within {remTime}',
          description: 'Error message when invalid files are uploaded.',
        },
        { remTime },
      )
    } else {
      return ''
    }
  }

  const msgDismissAlert = () => {
    return intl.formatMessage({ defaultMessage: 'Dismiss alert', description: 'Visually hidden label for dismissing an alert' })
  }

  return {
    unAuthError,
    somethingWentWrongError,
    maxUploadError,
    invalidFormatError,
    maxFileSizeError,
    callOutMessage,
    msgDismissAlert,
  }
}
