import React, { FunctionComponent } from 'react'
import TrackingJS from '@vp/react-tracking'
import { QLP_ENVIRONMENT, QLP_TENANT } from '../../constants/env.constants'
import { AnalyticsProps } from '../../types/analytics.types'

const Analytics: FunctionComponent<AnalyticsProps> = (props: AnalyticsProps) => {
  return <TrackingJS version="8" tenant={QLP_TENANT} environment={QLP_ENVIRONMENT} culture={props.locale} integrations={{ All: true }} />
}

export default Analytics
