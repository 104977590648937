import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import nophotoUploadedimage from '../../assets/icons/uploadInstruction.svg'

export const SiteMeta: FC<{ localeCulture: string }> = ({ localeCulture }) => {
  const intl = useIntl()
  return (
    <Helmet>
      <html lang={localeCulture} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={intl.formatMessage({ defaultMessage: 'VistaPrint QR landing page', description: 'SEO Open graph site name' })} />

      <meta name="theme-color" content="#006196" />
      <link rel="preload" as="image" href={nophotoUploadedimage} />
    </Helmet>
  )
}
