import { Box, LinkList, LinkListHeading, ListItem } from '@vp/swan'
import { Link, PageProps } from 'gatsby'
import React, { FC } from 'react'
import { IntlProvider } from 'react-intl'
import { PageContextType } from '../types/applications.types'
import '../styles/index.scss'
import { noOp } from '../utils/utils'

const IndexPage: FC<PageProps<unknown, PageContextType>> = ({ pageContext }) => {
  return (
    <IntlProvider messages={pageContext.messages} locale={pageContext.localeCulture} defaultLocale="en-US" onError={noOp}>
      <Box align="center" mt={10}>
        <LinkListHeading>Available pages</LinkListHeading>
        <LinkList skin="standard">
          <ListItem>
            <Link to="en-us/qlp/upload">EN-US - Upload</Link>
          </ListItem>
          <ListItem>
            <Link to="en-ca/qlp/upload">EN-CA - Upload</Link>
          </ListItem>
          <ListItem>
            <Link to="es-us/qlp/upload">ES-US - Upload</Link>
          </ListItem>
          <ListItem>
            <Link to="fr-ca/qlp/upload">FR-CA - Upload</Link>
          </ListItem>
        </LinkList>
      </Box>
    </IntlProvider>
  )
}
export default IndexPage
