import { PageProps } from 'gatsby'
import React, { FC } from 'react'
import TemplateWrapper from '../../lib/templateWrapper'
import { PageContextType } from '../../types/applications.types'
import UploadContent from './uploadContent'

const UploadPage: FC<PageProps<unknown, PageContextType>> = ({ pageContext }) => {
  return (
    <TemplateWrapper pageContext={pageContext}>
      <UploadContent />
    </TemplateWrapper>
  )
}

export default UploadPage
