import { allSupportedFileTypeMappings } from '../utils/constants'
import { convertToTypeState, createObjectUrl, extension } from '../utils/utils'
import { useFileMethods } from './useFileMethods.hooks'
import { usePreviewFiles } from './usePreviewFiles.hooks'
import { usePreview } from '../contexts/preview-images.context'
import { PreviewFilesStatusType } from '../types/preview.types'
import { v4 as uuidv4 } from 'uuid'
import { useMessages } from './intl.hooks'
import { useCallback } from 'react'
import { useErrorToastWithDuration } from './toast.hooks'

/**
 * Hook returns a function uploadFiles for uploading multiple files
 */
const BYTES_TO_KB = 1024
const maxUploads = 0
const maxFileSizeInKilobytes = 500000

export const useUploadFiles = () => {
  const errorToast = useErrorToastWithDuration()
  const { fileExtensions, fileExtensionsAsString } = convertToTypeState(allSupportedFileTypeMappings)
  const { uploadFile } = useFileMethods()
  const previewFiles = usePreviewFiles()
  const setPreview = usePreview()
  const { maxUploadError, invalidFormatError, maxFileSizeError } = useMessages()

  const addFiles = useCallback(
    (status: number | undefined, file: File) => {
      const url = createObjectUrl(file)

      const listObj = {
        status: status,
        url: url,
        name: file.name,
        type: file.type,
        isLoading: true,
        id: uuidv4(),
      }
      setPreview({
        type: 'ADD',
        list: listObj,
      })
      return listObj
    },
    [setPreview],
  )

  const uploadFiles = useCallback(
    async (files: FileList | File[]) => {
      if (maxUploads > 0 && files.length > maxUploads) {
        const errorMessage = maxUploadError(maxUploads)
        errorToast(errorMessage)
        return
      }

      if (files && files.length > 0) {
        const uploads: Promise<{ response: Response; previewFiles: PreviewFilesStatusType }>[] = []

        for (const file of files) {
          if (!fileExtensions.includes(extension(file?.name))) {
            const errorMessage = invalidFormatError(fileExtensionsAsString, file?.name)
            errorToast(errorMessage)
          } else if (maxFileSizeInKilobytes && file.size / BYTES_TO_KB > maxFileSizeInKilobytes) {
            const fileSize = Math.round(file.size / BYTES_TO_KB)
            const errorMessage = maxFileSizeError(maxFileSizeInKilobytes, file?.name, fileSize)
            errorToast(errorMessage)
          } else {
            const previewFile = addFiles(undefined, file)
            const promise = uploadFile(file, previewFile)
            uploads.push(promise)
          }
        }

        const uploadedData = await Promise.allSettled(uploads)
        previewFiles(uploadedData)
      }
    },
    [uploadFile, fileExtensions, fileExtensionsAsString, addFiles, errorToast, invalidFormatError, maxFileSizeError, maxUploadError, previewFiles],
  )

  return uploadFiles
}
