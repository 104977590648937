import { previewFilesDataType } from '../types/preview.types'
import { PreviewFilesStatusType } from '../types/preview.types'
import { usePreview } from '../contexts/preview-images.context'
import { useMessages } from '../hooks/intl.hooks'
import { useErrorToastWithDuration } from './toast.hooks'
import { useEventTracking } from './tracking.hooks'
import { useProductDetailsContext } from '../contexts/app.context'

/**
 * Hook returns a function which is used for adding the preview for files after upload.
 */

export const usePreviewFiles = () => {
  const errorToast = useErrorToastWithDuration()
  const eventTracking = useEventTracking()
  const { side } = useProductDetailsContext()
  const { unAuthError, somethingWentWrongError } = useMessages()
  const setPreview = usePreview()
  const editFiles = (status: number | undefined, fileItem: PreviewFilesStatusType) => {
    const listObj = {
      status: status,
      url: fileItem.url,
      name: fileItem.name,
      type: fileItem.type,
      isLoading: false,
      id: fileItem.id,
    }
    setPreview({
      type: 'EDIT',
      list: listObj,
    })
  }

  const previewFiles = async (filesList: previewFilesDataType[]) => {
    let unAuthorizedError = 0
    let unknownError = 0
    let success = 0

    for (const fileItem of filesList) {
      if (fileItem.status === 'rejected' && fileItem.reason) {
        unknownError += 1
        editFiles(500, fileItem.reason.previewFiles)
      } else {
        const file = fileItem.value
        const responseStatus = fileItem.value?.response.status

        if (file && responseStatus === 201) {
          editFiles(201, file.previewFiles)
          success += 1
        } else if (file && responseStatus === 401) {
          unAuthorizedError += 1
          editFiles(401, file.previewFiles)
        } else {
          if (fileItem.value) {
            unknownError += 1
            editFiles(500, fileItem.value.previewFiles)
          }
        }
      }
    }
    if (success > 0) {
      eventTracking({ eventDetail: `uploadMobile:imagepanel:success:${side?.toLocaleLowerCase()};${success}` })
    }

    if (unAuthorizedError > 0) {
      eventTracking({ eventDetail: `uploadMobile:imagepanel:error:${side?.toLocaleLowerCase()}` })
      const errorLabel = unAuthError(filesList.length, unAuthorizedError)
      errorToast(errorLabel)
    }

    if (unknownError > 0) {
      eventTracking({ eventDetail: `uploadMobile:imagepanel:error:${side?.toLocaleLowerCase()}` })
      const errorLabel = somethingWentWrongError(filesList.length, unknownError)
      errorToast(errorLabel)
    }
  }

  return previewFiles
}
