import React from 'react'
import { useUrlExpiredContext } from '../../contexts/app.context'
import ExpiredPage from '../../components/ExpiredPage'
import PreviewWrapper from '../../components/PreviewWrapper'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const UploadContent = () => {
  const isUrlExpired = useUrlExpiredContext()
  return (
    <>
      {isUrlExpired ? (
        <ExpiredPage />
      ) : (
        <>
          <Header />
          <PreviewWrapper />
          <Footer />
        </>
      )}
    </>
  )
}

export default UploadContent
