import React, { useRef, forwardRef, useState } from 'react'
import { Button, Icon } from '@vp/swan'
import { FormattedMessage } from 'react-intl'
import { useUploadFiles } from '../../hooks/useuploadFiles.hooks'
import { convertToTypeState, parseBool } from '../../utils/utils'
import { allSupportedFileTypeMappings } from '../../utils/constants'
import { useMultiUploadQueryParam } from '../../contexts/query-param.context'

export interface UploadsButtonProps extends React.ComponentProps<typeof Button> {
  multiple?: boolean
  children?: React.ReactNode
}

export const UploadsButton = forwardRef<HTMLButtonElement, UploadsButtonProps>((props, ref) => {
  const { onClick } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [firstClick, setFirstClick] = useState<boolean>(false)
  const uploadFiles = useUploadFiles()
  const multiUpload = parseBool(useMultiUploadQueryParam())
  const { fileExtensionsAsString } = convertToTypeState(allSupportedFileTypeMappings)

  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const selectedFiles = e?.target?.files
    if (selectedFiles) {
      if (inputRef.current) {
        setFirstClick(true)
        uploadFiles(selectedFiles)
        inputRef.current.value = ''
      }
    }
  }

  const onTextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (inputRef && inputRef?.current) {
      inputRef.current.click()
    }
    onClick?.(event)
  }

  return (
    <>
      <input accept={fileExtensionsAsString} ref={inputRef} type="file" multiple={multiUpload || firstClick} style={{ display: 'none' }} onChange={onChange} />
      <Button onClick={onTextClick} skin="primary" ref={ref} width="full-width" data-testid="uploadButton">
        <FormattedMessage defaultMessage="Upload Photos" description="Button text for uploading a photo" />
        <Icon iconType="upload" size="32p" skin="white" />
      </Button>
    </>
  )
})

UploadsButton.displayName = 'UploadsButton'
