import React, { FC } from 'react'
import { Box, FlexBox, H2, Typography } from '@vp/swan'
import warning from './warning.svg'
import './index.scss'
import { FormattedMessage } from 'react-intl'

const ExpiredPage: FC<unknown> = () => {
  return (
    <FlexBox className="expire-page-container" justifyContent="center" alignItems="center" flexDirection={'column'} p={4}>
      <Box>
        <Box mr={{ xs: 0, md: 2 }} ml={{ xs: 2, md: 0 }}>
          <img src={warning} alt="warning" />
        </Box>
        <H2 fontSize="x2" fontWeight="bold" mb={4}>
          <FormattedMessage defaultMessage="We’re sorry!" description="Header text in the url expire page" />
        </H2>
        <Typography as={'p'} fontSize="xm1" fontWeight={'bold'} mb={2}>
          <FormattedMessage
            defaultMessage="This mobile upload URL has expired."
            description="Expire mesaage callout in url expire page"
            values={{
              b: chunks => <b>{chunks}</b>,
            }}
          />
        </Typography>
        <Typography as={'p'} fontSize="xm1" mb={6}>
          <FormattedMessage
            defaultMessage="To continue uploading from this mobile device, get a new QR code link from the Vista studio editor on your computer"
            description="Expire mesaage description in url expire page"
          />
        </Typography>
      </Box>
    </FlexBox>
  )
}

export default ExpiredPage
