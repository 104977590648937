import { FileTypeMapping } from '../types/upload.configuration.type'

/**
 * List of image file types supported.
 */

export const basicImageTypeMappings: FileTypeMapping[] = [
  {
    fileType: 'image/jpeg',
    extensions: ['jpg', 'jpeg', 'jfif'],
  },
  {
    fileType: 'image/bmp',
    extensions: ['bmp'],
  },
  {
    fileType: 'image/png',
    extensions: ['png'],
  },
  {
    fileType: 'image/gif',
    extensions: ['gif'],
  },
]

export const basicPdfFileTypeMappings: FileTypeMapping[] = [
  {
    fileType: 'application/pdf',
    extensions: ['pdf'],
  },
]

/**
 * List of pdf file types supported
 */
export const otherFileTypeMappings: FileTypeMapping[] = [
  {
    fileType: 'image/vnd.adobe.photoshop',
    extensions: ['psd'],
  },
  {
    fileType: 'application/postscript',
    extensions: ['ai', 'eps'],
  },
  {
    fileType: 'application/vnd.dvb.ait',
    extensions: ['ait'],
  },
  {
    fileType: 'application/vnd.ms-powerpoint',
    extensions: ['ppt'],
  },
  {
    fileType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extensions: ['pptx'],
  },
  {
    fileType: 'image/tiff',
    extensions: ['tif', 'tiff'],
  },
  {
    fileType: 'image/heic',
    extensions: ['heic'],
  },
]

export const basicImageFileTypeMappings: FileTypeMapping[] = [...basicImageTypeMappings, ...basicPdfFileTypeMappings]
/**
 * Combination of all file types supported.
 */
export const allSupportedFileTypeMappings = basicImageFileTypeMappings.concat(otherFileTypeMappings)
