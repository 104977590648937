import React, { FC, memo } from 'react'
import { Typography, Box } from '@vp/swan'
import { useMessages } from '../../hooks/intl.hooks'
import { useTimerContext } from '../../contexts/app.context'

const Callout: FC<unknown> = () => {
  const { callOutMessage } = useMessages()
  const minutes = useTimerContext()
  const minuteLabel = callOutMessage(minutes)

  return (
    <Box pt={3} pb={4}>
      <Typography as={'p'} fontSize="xm2" textAlign="center" data-testid="component">
        {minuteLabel}
      </Typography>
    </Box>
  )
}

export default memo(Callout)
