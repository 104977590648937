import React, { FC, memo, useContext } from 'react'
import { Box, FlexBox, Typography, FluidImage } from '@vp/swan'
import './index.scss'
import { useProductDetailsContext } from '../../contexts/app.context'
import defaultImage from '../../assets/icons/defaultImage.svg'
import { isProductDetailsShown } from '../../utils/utils'
import { PreviewContext } from '../../contexts/preview-images.context'
import { FormattedMessage } from 'react-intl'

const ProductDetails: FC<unknown> = () => {
  const productDetails = useProductDetailsContext()
  const { previewList } = useContext(PreviewContext)

  const showProductDetails = isProductDetailsShown(productDetails.productName, productDetails.productSize)

  const ProductDetailsComponent = () => (
    <Box className="product-details-container">
      <FlexBox className="product-details-wrapper">
        {productDetails.previewImage ? (
          <Box className="product-image-wrapper">
            {' '}
            <FluidImage className="product-image" src={productDetails.previewImage} alt="product image missing" />
          </Box>
        ) : (
          <Box className="product-default-image-wrapper">
            <FluidImage className="product-image-default" src={defaultImage} />
          </Box>
        )}
        <Box className="product-details-text-wrapper">
          <Typography fontSize="xm2" as={'p'} pb={1}>
            <FormattedMessage defaultMessage="uploading photos for" description="Product Description callout" />
          </Typography>
          <Typography fontSize="x0" as={'p'} fontWeight="bold" truncate>
            {productDetails.productName}
          </Typography>
          <Typography as={'p'} fontSize="xm1">
            {productDetails.productSize}
          </Typography>
        </Box>
      </FlexBox>
    </Box>
  )

  return showProductDetails ? (
    <ProductDetailsComponent></ProductDetailsComponent>
  ) : !previewList.length ? (
    <Box className="product-no-details-container"></Box>
  ) : (
    <></>
  )
}

export default memo(ProductDetails)
