import React, { FC, memo } from 'react'
import { FlexBox, Typography } from '@vp/swan'
import './index.scss'
import { FormattedMessage } from 'react-intl'

const NoPhostosUploaded: FC<unknown> = () => {
  return (
    <FlexBox className="no-photo-uploaded-wrapper" flexDirection={'column'}>
      <Typography fontSize="xm1" textAlign={'left'} mt={1} as={'p'} fontWeight={'bold'}>
        <FormattedMessage defaultMessage="Accepted formats" description="A text description to show accepted formats." />
      </Typography>
      <Typography fontSize="xm1" textAlign={'left'} mt={1} as={'p'}>
        {'.jpg, .jpeg, .jfif, .bmp, .png, .gif, .heic, .svg, .pdf, .psd, .ai, .eps, .ait, .ppt, .pptx, .tif, .tiff'}
      </Typography>
    </FlexBox>
  )
}

export default memo(NoPhostosUploaded)
