import React, { FC, memo } from 'react'
import { H1, Box } from '@vp/swan'
import { FormattedMessage } from 'react-intl'
import './index.scss'

const Header: FC<unknown> = () => {
  return (
    <Box as="header" className="headerContainer" paddingX={4} paddingY={4} pt={5}>
      <H1 fontSize="x2">
        <FormattedMessage defaultMessage="Add photos" description="It is a header text for the landing page" />
      </H1>
    </Box>
  )
}

export default memo(Header)
