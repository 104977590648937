import React, { FC, memo } from 'react'
import { Box } from '@vp/swan'
import { UploadsButton } from '../uploadButton'
import Callout from '../Callout'
import './index.scss'

const Footer: FC<unknown> = () => {
  return (
    <Box as="section" className="footer-container" pt={6} paddingX={4}>
      <UploadsButton />
      <Callout />
    </Box>
  )
}

export default memo(Footer)
