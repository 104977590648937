import React, { FC, memo } from 'react'
import { PreviewFilesStatusType } from '../../../../types/preview.types'
import { Box, FlexBox, FluidImage, Icon, Spinner } from '@vp/swan'
import UploadFail from '../../../../assets/icons/uploadFail.svg'
import { checkSuccessStatus, getExtentionforfile, isImageType } from '../../../../utils/utils'
import clsx from 'clsx'
import './index.scss'

const getSwanComponentForFileType = (fileTypes: string, status: number | undefined) => {
  const extention = getExtentionforfile(fileTypes)
  switch (extention) {
    case 'pdf': {
      return <Icon size="60p" iconType="filePdf" className={clsx('preview-image-default', !checkSuccessStatus(status) && 'upload-fail-opacity')} />
    }
    case 'psd': {
      return <Icon size="60p" iconType="filePsd" className={clsx('preview-image-default', !checkSuccessStatus(status) && 'upload-fail-opacity')} />
    }
    case 'eps': {
      return <Icon size="60p" iconType="fileEps" className={clsx('preview-image-default', !checkSuccessStatus(status) && 'upload-fail-opacity')} />
    }
    case 'ai': {
      return <Icon size="60p" iconType="fileAi" className={clsx('preview-image-default', !checkSuccessStatus(status) && 'upload-fail-opacity')} />
    }
    default: {
      return <Icon size="60p" iconType="fileGeneric" className={clsx('preview-image-default', !checkSuccessStatus(status) && 'upload-fail-opacity')} />
    }
  }
}
const Preview: FC<{ previewItem: PreviewFilesStatusType }> = ({ previewItem }) => {
  return (
    <Box className="preview-item-container" key={previewItem.id} mr={3} mt={3}>
      <Box className={clsx('preview-item-wrapper', checkSuccessStatus(previewItem.status) && !isImageType(previewItem.type) && 'tile-background')}>
        <Box className="preview-Item">
          {previewItem.isLoading ? (
            <FlexBox justifyContent={'center'} alignItems={'center'} className="spinner-container">
              {' '}
              <Spinner size="super" accessibleText="Loading..." />{' '}
            </FlexBox>
          ) : isImageType(previewItem.type) ? (
            <FluidImage
              className={clsx('preview-image', !checkSuccessStatus(previewItem.status) && 'upload-fail-opacity')}
              src={previewItem.url}
              alt={previewItem.name}
              loading="lazy"
            />
          ) : (
            <FlexBox justifyContent={'center'} alignItems={'center'} className="spinner-container">
              {getSwanComponentForFileType(previewItem.name, previewItem.status)}
            </FlexBox>
          )}
        </Box>
        {!checkSuccessStatus(previewItem.status) && !previewItem.isLoading ? <FluidImage src={UploadFail} className="upload-fail" /> : <></>}
      </Box>
    </Box>
  )
}

export default memo(Preview)
