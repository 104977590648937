import React, { memo } from 'react'
import { TemplateWrapperType } from '../../types/applications.types'
import { SwanConfiguration } from '../swanConfig/swanConfiguration'
import { IntlProvider } from 'react-intl'
import Analytics from '../analytics/analytics'
import './index.scss'
import { Box } from '@vp/swan'
import { noOp } from '../../utils/utils'
import { SiteMeta } from '../sitemeta/sitemeta'
import { QueryParamsProvider } from '../../contexts/query-param.context'
import { ProductDetailsProvider, UrlExpiredProvider, UrlTimerProvider } from '../../contexts/app.context'
import { PreviewProvider } from '../../contexts/preview-images.context'
import { ToastProvider } from '../../contexts/toast.context'

const SingleRenderConfig: React.FC<TemplateWrapperType> = ({ pageContext, children }) => (
  <IntlProvider messages={pageContext.messages} locale={pageContext.localeCulture} defaultLocale="en-US" onError={noOp}>
    <SiteMeta localeCulture={pageContext.localeCulture} />
    {children}
  </IntlProvider>
)
const MemoizedSingleRenderConfig = memo(SingleRenderConfig)

const DataProviderConfigs: React.FC<{
  children: JSX.Element
  search: string
}> = ({ children, search }) => (
  <QueryParamsProvider search={search}>
    <ProductDetailsProvider search={search}>
      <UrlExpiredProvider search={search}>
        <PreviewProvider>
          <ToastProvider>
            <UrlTimerProvider>{children}</UrlTimerProvider>
          </ToastProvider>
        </PreviewProvider>
      </UrlExpiredProvider>
    </ProductDetailsProvider>
  </QueryParamsProvider>
)
const MemoizedDataProviderConfig = memo(DataProviderConfigs)

const TemplateWrapper: React.FC<TemplateWrapperType> = ({ children, pageContext }) => {
  const searchUrl = typeof window !== 'undefined' ? window.location.search : ''
  return (
    <>
      <SwanConfiguration localeCulture={pageContext.localeCulture}>
        <>
          <Analytics locale={pageContext.localeCulture} />
          <MemoizedSingleRenderConfig pageContext={pageContext}>
            <MemoizedDataProviderConfig search={searchUrl}>
              <Box as="main" className="page-wrapper">
                {children}
              </Box>
            </MemoizedDataProviderConfig>
          </MemoizedSingleRenderConfig>
        </>
      </SwanConfiguration>
    </>
  )
}

export default TemplateWrapper
