import { useTracking } from '@vp/react-tracking'
import { TrackDataType } from '../types/analytics.types'
import { useProductDetailsContext } from '../contexts/app.context'

export function useEventTracking() {
  const { tracking } = useTracking()
  const { key, version } = useProductDetailsContext()

  const eventTracking = async (trackData: TrackDataType) => {
    if (trackData && key && version) {
      tracking.track('Design Tool Used', {
        category: 'Studio',
        label: 'General',
        eventDetail: trackData.eventDetail,
        core_product_id: key,
        core_product_version: version,
        pageSection: 'Studio',
        pageStage: 'Design',
        pageName: `${key}:Studio`,
      })
    }
  }
  return eventTracking
}
