import { useSignedurlQueryParam } from '../contexts/query-param.context'
import { PreviewFilesStatusType } from '../types/preview.types'

type FileMethodsReturnType = {
  /**
   * Function to call when uploading a file or a uri. Options is an optional parameter.
   */
  uploadFile: (uploadData: File, previewFiles: PreviewFilesStatusType) => Promise<{ response: Response; previewFiles: PreviewFilesStatusType }>
}

/**
 * Hook that returns a function which makes a call to sherbert
 */
export const useFileMethods = (): FileMethodsReturnType => {
  const preSignedUrl = useSignedurlQueryParam()

  const uploadFile: FileMethodsReturnType['uploadFile'] = async (uploadData, previewFiles) => {
    if (!preSignedUrl || !uploadData) {
      const error = new Error('preSignedUrl and uploadData are required')
      throw { error, previewFiles }
    }

    const formdata = new FormData()
    formdata.append('file', uploadData)
    formdata.append('tags', JSON.stringify(['QR-Mobile-Upload']))

    const requestOptions = {
      method: 'POST',
      body: formdata,
    }

    try {
      const response = await fetch(preSignedUrl, requestOptions)
      return { response, previewFiles }
    } catch (error) {
      throw { error, previewFiles }
    }
  }

  return {
    uploadFile,
  }
}
