import React, { ReactNode, useEffect, useState, createContext, FC, useContext, useMemo } from 'react'
import { useExpiresTimeQueryParam } from './query-param.context'
import { getMinute } from '../utils/utils'

const checkIfUrlExpired = (urlExpirationTime: Date, setIsUrlExpired: React.Dispatch<React.SetStateAction<boolean>>) => {
  const currentTime = new Date()
  if (currentTime > urlExpirationTime) setIsUrlExpired(true)
  else setIsUrlExpired(false)
}

const UrlExpiredContext = createContext<boolean>(false)
export const useUrlExpiredContext = () => useContext(UrlExpiredContext)
let IntervalId: ReturnType<typeof setInterval>

export const UrlExpiredProvider: FC<{ children: ReactNode; search: string }> = ({ children, search }) => {
  const [expiresTimeQueryParam, setExpiresTimeQueryParam] = useState<string | null>()
  const urlExpirationTime = useMemo(() => (expiresTimeQueryParam ? new Date(expiresTimeQueryParam) : null), [expiresTimeQueryParam])
  const [isUrlExpired, setIsUrlExpired] = useState<boolean>(false)

  useEffect(() => {
    if (!search) {
      setIsUrlExpired(true)
    }

    const searchParams = new URLSearchParams(search)
    const preSignedUrlVal = searchParams.get('uploadUrl')
    const expiresTimeQueryParamVal = searchParams.get('expiresOn')
    if (!preSignedUrlVal || !expiresTimeQueryParamVal) {
      setIsUrlExpired(true)
    }
    setExpiresTimeQueryParam(expiresTimeQueryParamVal)
  }, [search])

  useEffect(() => {
    if (!isUrlExpired && urlExpirationTime) {
      IntervalId = setInterval(checkIfUrlExpired, 1000, urlExpirationTime, setIsUrlExpired)
    }
    return () => {
      clearInterval(IntervalId)
    }
  }, [isUrlExpired, urlExpirationTime])

  return <UrlExpiredContext.Provider value={isUrlExpired}>{children}</UrlExpiredContext.Provider>
}

export type Metadata = {
  previewImage?: string
  productName: string
  productSize: string
  key?: string
  version?: string
  side?: string
}

const defaultMetadata = {
  productName: '',
  productSize: '',
}

const ProductDetailsContext = createContext<Metadata>(defaultMetadata)
export const useProductDetailsContext = () => useContext(ProductDetailsContext)

export const ProductDetailsProvider: FC<{ children: ReactNode; search: string }> = ({ children, search }) => {
  const searchParams = new URLSearchParams(search)
  const metadataQueryParam = searchParams.get('metadata')
  const [productdetails, setProductdetails] = useState<Metadata>(defaultMetadata)

  useEffect(() => {
    if (metadataQueryParam) {
      setProductdetails(JSON.parse(metadataQueryParam))
    }
  }, [metadataQueryParam])

  return <ProductDetailsContext.Provider value={productdetails}>{children}</ProductDetailsContext.Provider>
}

const UrlTimerContext = createContext<string>('')
export const useTimerContext = () => useContext(UrlTimerContext)
let interval: ReturnType<typeof setInterval>

export const UrlTimerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const expiresTimeQueryParam = useExpiresTimeQueryParam()
  const urlExpirationTime = useMemo(() => (expiresTimeQueryParam ? new Date(expiresTimeQueryParam) : null), [expiresTimeQueryParam])
  const [timerinMinutes, setTimerinMinutes] = useState<string>('')
  const isUrlExpired = useUrlExpiredContext()

  useEffect(() => {
    if (!isUrlExpired && urlExpirationTime) {
      setTimerinMinutes(getMinute(urlExpirationTime))
      interval = setInterval(() => {
        setTimerinMinutes(getMinute(urlExpirationTime))
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [urlExpirationTime, isUrlExpired])

  return <UrlTimerContext.Provider value={timerinMinutes}>{children}</UrlTimerContext.Provider>
}
