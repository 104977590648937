import React, { ReactNode, createContext, FC, useContext, useEffect, useState } from 'react'

const QueryParamContext = createContext<URLSearchParams>(new URLSearchParams({}))

export const QueryParamsProvider: FC<{ children: ReactNode; search: string }> = ({ children, search }) => {
  const [value, setValue] = useState(new URLSearchParams({}))
  useEffect(() => {
    setValue(prev => {
      if (prev.toString() === search) return prev
      return new URLSearchParams(search)
    })
  }, [search])
  return <QueryParamContext.Provider value={value}>{children}</QueryParamContext.Provider>
}

export function useQueryParam(key: string): string {
  const queryParam = useContext(QueryParamContext)
  return queryParam.get(key) ?? ''
}

export function useExpiresTimeQueryParam() {
  return useQueryParam('expiresOn')
}

export function useSignedurlQueryParam() {
  return useQueryParam('uploadUrl')
}

export function useMetadataQueryParam() {
  return useQueryParam('metadata')
}

export function useMultiUploadQueryParam() {
  return useQueryParam('multiUpload')
}
