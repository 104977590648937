import { useIsInBrowser } from '../hooks/is-in-browser.hook'
import React, { createContext, useContext, useReducer } from 'react'
import { createPortal } from 'react-dom'
import { v4 as uuidv4 } from 'uuid'
import { Toast } from '../lib/toast'
import '../lib/toast/index.scss'
import { ToastAction, ToastConfig, ToastContextValue } from '../types/toast.type'
import { ToastProviderprops } from '../types/toast.type'
import { noOp } from '../utils/utils'

export const ToastContext = createContext<ToastContextValue>(noOp)
export function toastReducer(state: ToastConfig[], action: ToastAction) {
  switch (action.type) {
    case 'ADD':
      return [...state, { id: uuidv4(), ...action.config }]
    case 'REMOVE':
      return state.filter(toast => toast.id !== action.id)
    default:
      return state
  }
}

export const ToastProvider: React.FC<ToastProviderprops> = ({ children }) => {
  const [toasts, setToasts] = useReducer(toastReducer, [])
  const isInBrowser = useIsInBrowser()
  return (
    <ToastContext.Provider value={setToasts}>
      {children}
      {isInBrowser &&
        createPortal(
          <div className="toast-container">
            {toasts.map(toast => (
              <Toast
                key={toast.id}
                remove={() => setToasts({ type: 'REMOVE', id: toast.id })}
                message={toast.content}
                duration={toast.duration}
                skin={toast.skin}
              />
            ))}
          </div>,
          document.body,
        )}
    </ToastContext.Provider>
  )
}

export function useToast(): ToastContextValue {
  const dispatch = useContext(ToastContext)
  if (dispatch === undefined) {
    throw new Error("toast should be used inside it's provider")
  }
  return dispatch
}
