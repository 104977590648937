import React, { createContext, FC, useContext, useReducer } from 'react'

import { PreviewAction, PreviewFilesStatusType, PreviewContextValue, PreviewProviderprops } from '../types/preview.types'
import { noOp } from '../utils/utils'

export const PreviewContext = createContext<PreviewContextValue>({
  previewList: [],
  setPreviewList: noOp,
})
export function previewReducer(state: PreviewFilesStatusType[], action: PreviewAction) {
  switch (action.type) {
    case 'ADD': {
      const currentState = [...state]
      currentState.unshift(action.list)
      return [...currentState]
    }
    case 'EDIT': {
      const currentState = [...state]
      const index = currentState.findIndex(item => item.id === action.list.id)
      currentState.splice(index, 1, action.list)
      return [...currentState]
    }
    default:
      return state
  }
}

export const PreviewProvider: FC<PreviewProviderprops> = ({ children }) => {
  const [previewList, setPreviewList] = useReducer(previewReducer, [])
  return <PreviewContext.Provider value={{ previewList, setPreviewList }}>{children}</PreviewContext.Provider>
}

export function usePreview(): React.Dispatch<PreviewAction> {
  const { setPreviewList } = useContext(PreviewContext)
  if (typeof setPreviewList === undefined) {
    throw new Error("toast should be used inside it's provider")
  }
  return setPreviewList
}
