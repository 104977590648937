import { FileTypeMapping, UploadFileTypes } from '../types/upload.configuration.type'
import { basicImageTypeMappings } from './constants'

export const extension = (fileName: string): string => {
  const splitName = fileName.split('.')
  return splitName[splitName.length - 1].toLocaleLowerCase()
}

export const convertToTypeState = (supportedFileTypes: FileTypeMapping[]): UploadFileTypes => {
  const acceptedTypes = [...new Set(supportedFileTypes.map(item => item.fileType))]
  const fileExtensions = [...new Set(supportedFileTypes.map(item => item.extensions).reduce((array, item) => array.concat(item), []))]
  const acceptedTypesAsString = acceptedTypes.join(', ')
  const fileExtensionsAsString = fileExtensions.map(item => `.${item}`).join(', ')
  return {
    acceptedTypes,
    acceptedTypesAsString,
    fileExtensions,
    fileExtensionsAsString,
  }
}

export const isImageType = (FileTypes: string): boolean => {
  const isIamge = basicImageTypeMappings.find(el => el.fileType === FileTypes)
  if (isIamge) return true
  return false
}

export const checkSuccessStatus = (status: number | undefined) => {
  if (status === 201) {
    return true
  }
  return false
}

export const getMinute = (urlExpirationTime: Date | null) => {
  if (!urlExpirationTime) {
    return ''
  }
  const currentTime = new Date()
  const diff = urlExpirationTime.getTime() - currentTime.getTime()
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)

  if (isNaN(minutes) || isNaN(seconds)) {
    return ''
  }

  return minutes + 'm ' + seconds + 's '
}

export function noOp(): void {
  return undefined
}

export const isProductDetailsShown = (productName: string, productSize: string) => {
  return productName && productSize
}

export function getExtentionforfile(fileName: string) {
  return fileName ? fileName.split('.').pop()?.toLowerCase() : ''
}

export function createObjectUrl(file: File) {
  return URL.createObjectURL(file)
}

export function parseBool(val: string) {
  return val === 'true'
}
