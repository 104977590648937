import React, { FC, useEffect, useRef } from 'react'
import { PreviewFilesStatusType } from '../../types/preview.types'
import PreviewItem from './components/PreviewImage'
import { Box, FlexBox } from '@vp/swan'
import './index.scss'

const Preview: FC<{ previewList: PreviewFilesStatusType[] }> = ({ previewList }) => {
  const previewRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (previewRef.current) {
      previewRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [previewList])

  return (
    <Box className="preview-container" ref={previewRef}>
      <FlexBox alignItems={'center'} flexWrap="wrap">
        {previewList.map(previewItem => {
          return <PreviewItem previewItem={previewItem} key={previewItem.id} />
        })}
      </FlexBox>
    </Box>
  )
}

export default Preview
